import axios from "axios"
import AppDevice from "./Class/device"
import AppAxios from "./Class/axios"



export async function _iniciar(){
    await AppDevice.DeviceInit()
    await AppAxios.Start()
    window.localStorage.setItem('ConfiguracionGeneral',JSON.stringify(AppDevice.toJson()))
    let token = localStorage.getItem('MedId')
    if(token === null){
        //console.log('no hay token')
        return({configuraciones:AppDevice.toJson(),aut:{verificado:false}})
    }else{
        try{
            let authreq = await AppAxios.get(`/autenticacion/${token}`)
            let aut = authreq.data
            return({configuraciones:AppDevice.toJson(),aut:aut})
        }
        catch(e){
            throw e
        }
    }
}

export function mobil(){
    let toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    for(let i = 0 ; i<= toMatch.length-1 ; i++){
        if(navigator.userAgent.match(toMatch[i]) !== null){
            return {mobil:true,os:toMatch[i].source}
        }
    }
    return {mobil:false,os:null}
}