import React,{useState,useEffect} from 'react';
import './App.scss';
import {BrowserRouter,Route,Redirect} from "react-router-dom"
import * as funciones from './appfunciones'

//import ConsoltasApp from "./ConsultasApp"

import Login from './componentes/Login2/Login'
//import Visor from './componentes/NuevoVisor/Visor'
import Main from './componentes/Main3/Main'
import Externo from './componentes/Externo/Externo';

import Local from './componentes/Local/index';
import Cargando from './componentes/Externo/Cargando';


export const ContextoGeneral = React.createContext('')
function App() {
  const [iniciado, setiniciado] = useState({iniciado:false,autenticado:null})
  const [usuario, setusuario] = useState(null)
  const [configuraciones, setconfiguraciones] = useState(null)
  const [ErrorCon, setErrorCon] = useState(false)
  useEffect(()=>{
    window.sessionStorage.removeItem('filtrosmain')
    document.getElementById('InicioApp').style.display = "none"
    funciones._iniciar()
    .then((res)=>{
      if(res.aut.verificado){
        setusuario({...res.aut.usuario})
        setconfiguraciones({...res.configuraciones})
        setiniciado({iniciado:true,autenticado:true})
      }else{
        setusuario(null)
        setconfiguraciones({...res.configuraciones})
        setiniciado({iniciado:true,autenticado:false})
      }
    })
    .catch(e=>{
      //console.log(e)
      setErrorCon(true)
      setiniciado({iniciado:true,autenticado:false})
    })
  },[])

  if(!iniciado.iniciado){
    return(
       <Cargando mensaje="Iniciando APP"/>
      )
    }
    if(ErrorCon){
      return(
        <ErrorConCom/>
      )
    }
  let contextValue = {
    iniciado:iniciado,
    setiniciado:setiniciado,
    usuario:usuario,
    setusuario:setusuario,
    configuraciones:configuraciones,
    setconfiguraciones:setconfiguraciones,
  }

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      iniciado.autenticado === true
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  )
  return (
    <ContextoGeneral.Provider value={contextValue}>
      <div className="App">
        <BrowserRouter>
          <Route path="/" exact component={Login}/>
          <Route path="/login" component={Login}/>
          <PrivateRoute path="/main" component={Main}/>
          <PrivateRoute path="/visor/:id" component={Local}/>
          <Route path="/externo/:token" component={Externo}/>
          <Route path="//externo/:token" component={Ext2}/>
          <Route path="/test" component={Main}/>
        </BrowserRouter>
      </div>
    </ContextoGeneral.Provider>
  );
}

//////eliminar en siguiente update///////////
export default App;
const ErrorConCom = ()=>{
  return(
    <div className='ConError'>
      <div className='contenedor'>
        <div className='imagen'>
          <img src="/IMG/conexion_lost.png" alt="" />
        </div>
        <div className='texto'>Conexion perdida con el servidor</div>
        <div className='exp'>Verifique la conexion a internet y recargue la app.</div>
      </div>
    </div>
  )
}
const Ext2 = (props)=>{
  props.history.push("/externo/"+props.match.params.token)
  return(
    <div>
      {"<========================>"}
    </div>
  )
}
